$breakpoints_xs: 400;
$breakpoints_sm: 575;
$breakpoints_md: 767;
$breakpoints_lg: 991;
$breakpoints_xl: 1199;
$breakpoints_xxl: 1399;


@mixin dsnSetMedia($width , $num_width) {
  @if ($width == min) {
    $num_width: $num_width + 1;
  }

  @media only screen and (#{$width}-width: #{$num_width}px) {
    @content;
  }
}

@mixin dsnSetMediaBetween($from , $to) {
  $from: $from + 1;

  @media only screen and (min-width: #{$from}px) and (max-width: #{$to}px) {
    @content;
  }
}


@mixin media_575($width : max) {
  @include dsnSetMedia($width, 575) {
    @content
  }

}


@mixin media_400($width : max) {
  @include dsnSetMedia($width, 400) {
    @content
  }
}


@mixin media_991($width : max) {
  @include dsnSetMedia($width, 991) {
    @content
  }

}


@mixin media_768($width : max) {
  @include dsnSetMedia($width, 767) {
    @content
  }
}


@mixin media_1200($width : max) {
  @include dsnSetMedia($width, 1200) {
    @content
  }

}

@mixin media_1400($width : max) {
  @include dsnSetMedia($width, 1399) {
    @content
  }

}


@mixin mediaUpSize($size) {
  @media only screen and (min-width: $size) {
    @content;
  }
}


@mixin mediaSize($size) {
  @media only screen and (max-width: $size) {
    @content;
  }
}

@mixin media_768X991() {
  @include dsnSetMediaBetween($breakpoints_md, $breakpoints_lg) {
    @content;
  }
}
@mixin media_575X768() {
  @include dsnSetMediaBetween($breakpoints_sm ,$breakpoints_md) {
    @content;
  }
}

@mixin media_575X991() {
  @include dsnSetMediaBetween($breakpoints_sm, $breakpoints_lg) {
    @content;
  }
}
@mixin media_991X1200() {
  @include dsnSetMediaBetween($breakpoints_lg, $breakpoints_xl) {
    @content;
  }
}

@mixin media_1200X1400() {
  @include dsnSetMediaBetween($breakpoints_xl, $breakpoints_xxl) {
    @content;
  }
}


@import "../../../assets/sass/helper";

.btn-default {
    display: inline-flex;
    align-items: center;
    padding: 20px 25px;
    border: 1px solid $border-color;
    border-radius: 7px;
    color: $heading-color;

    .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $heading-color;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-right: 10px;

        svg {
            width: 6px;

            path {
                fill: $bg-color;
            }
        }
    }

    .text {
        font-size: 16px;
        font-weight: 600;
    }

    &:hover {
        color: $heading-color;
    }
}

.btn-white {
    display: inline-flex;
    align-items: center;
    padding: 20px 25px;
    border: 1px solid white;
    border-radius: 7px;
    color: white;

    .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $heading-color;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-right: 10px;

        svg {
            width: 6px;

            path {
                fill: $bg-color;
            }
        }
    }

    .text {
        font-size: 16px;
        font-weight: 600;
    }

    &:hover {
        color: black;
        background-color: white;
    }
}

.btn-dark {
    display: inline-flex;
    align-items: center;
    padding: 20px 25px;
    border: 1px solid black;
    border-radius: 7px;
    color: black;

    .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $heading-color;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-right: 10px;

        svg {
            width: 6px;

            path {
                fill: $bg-color;
            }
        }
    }

    .text {
        font-size: 16px;
        font-weight: 600;
    }

    &:hover {
        color: white;
        background-color: black;
    }
}